<template>
  <div class="appointmentList-view">
    <div class="flex-a-b-c">
      <el-button class="v-primary" type="primary" @click="setGoodsType">新建分类</el-button>
      <div>
        <!-- <el-select v-model="value" placeholder="请选择" style="width: 240px">
          <el-option value="" label="全部" class="cbcc">全部</el-option>
          <el-option
            v-for="item in optionList"
            :key="item.productsTypeId"
            :label="item.productsTypeName"
            :value="item.productsTypeId"
          >
          </el-option>
        </el-select> -->
        <el-input v-model="searchKey" class="mb10" style="width: 240px" placeholder="请输入分类名称" />
        <el-button class="v-primary ml10" type="primary" @click="queryAppointmentPage">查询</el-button>
      </div>
    </div>

    <commonTable :tableData="tableData" @handleSizeChange="handleSizeChange" @handleCurrentChange="handleCurrentChange"
      :currentPage="currentPage" :loading="loading" :total="total">
      <template v-slot:table>
        <el-table-column prop="productsTypeId" width="100" align="center" label="分类ID" />
        <el-table-column prop="goodPhoto" align="center" label="图标" width="120px">
          <template slot-scope="scope">
            <el-image v-if="scope.row.typeIcon" style="width: 100px; height: 100px;margin-bottom:-10px"
              :src="scope.row.typeIcon">
            </el-image>
            <p v-else style="width: 100px; height: 100px;line-height: 100px">
              暂无图片
            </p>
          </template>
        </el-table-column>
        <el-table-column prop="dictLabel" align="center" label="大分类" />
        <el-table-column prop="productsTypeName" align="center" label="分类名称" show-overflow-tooltip />
        <el-table-column prop="productsCount" align="center" label="商品数量" />
        <el-table-column align="center" label="排序" width="80">
          <template slot-scope="scope">
            <span class="el-icon-upload2 hover_pointer" v-if="scope.$index != 0" style="font-size:20px"
              @click="goodsSort('up', scope.row.productsTypeId)"></span>
            <span class="el-icon-download hover_pointer" v-if="scope.$index != (tableData.length - 1)"
              style="font-size:20px" @click="goodsSort('down', scope.row.productsTypeId)"></span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="120">
          <template slot-scope="scope">
            <span class="mr10 hover_pointer cblue" @click="setGoodsType(scope.row)">编辑</span>
            <span class="hover_pointer corange" @click="deleteType(scope.row.productsTypeId)">删除</span>
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog :close-on-click-modal="false" :modal-append-to-body="false" :title="dialogTitle"
      :visible.sync="dialogVisible" :destroy-on-close="true" @close="closeFun" width="400px">
      <el-form ref="formData" :rules="rules" :model="formData" label-width="80px">
        <el-form-item label="图标" prop="typeIcon">
          <el-upload class="iconUpload" :action="$store.state.uploadingUrl" list-type="picture-card"
            :class="{ disabled: uploadDisabled }" :limit="1" :on-progress="linkmanImgProgress"
            :on-success="linkmanImgSuccess" :on-remove="linkmanImgRemove" :file-list="typeIcon">
            <i class="el-icon-plus"></i>
          </el-upload>
          <div class="h100p d-flex w200 align-items-center">
            <p>最多只能上传1张，建议上传100x100，格式可为png、jpg或jpeg</p>
          </div>
        </el-form-item>
        <el-form-item label="大分类">
          <el-select v-model="formData.dictValue" placeholder="请选择大分类" style="width: 240px">
            <el-option :label="row.dictLabel" :value="row.dictValue" v-for="(row, key) in maxGoodsTypeData" :key="key">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="分类名称" prop="productsTypeName">
          <el-input v-model="formData.productsTypeName" style="width: 240px"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="submitType">保 存</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  fetchProductsTypePage,
  fetchProductsTypeList,
  alterAppointmentGoodsTypeSort,
  addAppointmentGoodsType,
  delProductsType,
  getByDictType
} from "@/api/companyManage.js";
import { getDataTimeSec } from "@/utils";
export default {
  name: "appointmentList",
  components: {
    commonTable,
  },
  data() {
    return {
      userInfo: this.$store.state.loginRoot.userInfo,
      value: "",
      tableData: [],
      optionList: '', //全部分类
      loading: false,
      total: 0, //总条数
      currentPage: 1, //当前页数
      queryDate: "",
      pageNum: 1,
      pageSize: 10,
      searchKey: '', //关键字
      //新增分类配置
      dialogTitle: "",
      dialogVisible: false,
      maxGoodsTypeData: [],
      typeIcon: [],
      formData: {
        typeIcon: "",
        productsTypeName: "",
        dictValue: "",
      },
      rules: {
        typeIcon: {
          required: true,
          message: "请上传分类图标",
        },
        productsTypeName: {
          required: true,
          message: "请输入分类名称",
          trigger: "blur",
        },
      },
      formType:"add"
    };
  },
  computed: {
    setDataTimeSec() {
      return (data) => getDataTimeSec(data);
    },
    uploadDisabled() {
      return this.formData.typeIcon !== "";
    },
  },
  props: {
    jurisdiction: {
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    this.getTableData()
    // this.getTypeList()
    this.getInitData();
  },
  methods: {
    getInitData() {
      getByDictType({ dictType: "max_goods_type", status: 0 }).then((res) => {
        if (res.code === 200) {
          this.maxGoodsTypeData = res.data.sysDictDataList;
        }
        this.maxGoodsTypeData.unshift({
          dictLabel: "无",
          dictValue: "",
        });
      });
    },
    /**@method 关闭模态框清除图标 */
    closeFun() {
      this.formData.typeIcon = "";
      this.typeIcon = [];
    },
    //请求预约商品列表数据
    getTableData() {
      this.loading = true;
      let data = {
        ignore: true,
        pageNum: this.pageNum,
        pageSize: this.pageSize,
        productsTypeId: this.value,
        productsTypeName: this.searchKey
      };
      fetchProductsTypePage(data)
        .then((res) => {
          this.loading = false;
          this.tableData = res.data.pageInfo.list || "";
          this.total = res.data.pageInfo.total;
        })
        .catch((err) => {
          this.loading = false;
          this.tableData = [];
          //-console.log(err);
        });
    },
    //查询分类
    getTypeList() {
      fetchProductsTypeList({ ignore: true })
        .then((res) => {
          this.optionList = res.data;
        })
        .catch((err) => {
          //-console.log(err);
        });
    },
    //筛选查询符合条件数据
    queryAppointmentPage() {

      this.currentPage = 1;
      this.pageNum = 1;
      this.loading = true;
      this.getTableData();
    },
    //修改排序
    goodsSort(type, id) {
      // if (this.userInfo.roleId || this.jurisdiction) {
        let data = ''
        if (type == 'up') {
          data = {
            productsTypeId: id,
            type: 1,
          }
        } else {
          data = {
            productsTypeId: id,
            type: 2,
          }
        }
        alterAppointmentGoodsTypeSort(data)
          .then((res) => {
            this.$message({
              type: "success",
              message: "操作成功",
            });
            this.loading = true;
            this.getTableData()
          })
          .catch((err) => {
            //-console.log(err);
          });
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "暂无权限",
      //   });
      //   return
      // }

    },
    /**@method 删除图片 */
    linkmanImgRemove(file, fileList) {
      //-console.log(file, fileList);
      this.formData.typeIcon = "";
      this.typeIcon = [];
    },
    /**@method 图片上传 */
    linkmanImgProgress(event, file, fileList) {

    },
    /**@method 上传回调 */
    linkmanImgSuccess(response, file, fileList) {
      this.$message.closeAll();
      this.$message({
        message: "上传成功",
        type: "success",
      });
      this.formData.typeIcon = `https://one-stand-yuanqi366.oss-cn-guangzhou.aliyuncs.com/${response.data}`;
    },
    //新增
    setGoodsType(row) {
      // if (this.userInfo.roleId || this.jurisdiction) {
        if (!row) {
          this.formType='add'
          this.dialogTitle = "新增分类";
          this.formData = {
            typeIcon: "",
            productsTypeName: "",
            dictValue: "",
          };
          this.typeIcon = [];
        } else {
          this.formType='update'
          this.dialogTitle = "编辑分类";
          this.formData = {
            typeIcon: row.typeIcon ? row.typeIcon : "",
            productsTypeName: row.productsTypeName,
            dictValue: row.dictValue,
            goodstypeId: row.goodstypeId,
          };
          if (row.typeIcon) {
            this.typeIcon = [{ url: row.typeIcon }]
          }
        }
        this.dialogVisible = true;
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "暂无权限",
      //   });
      //   return
      // }

    },
    //提交添加/修改
    submitType() {
      // if (this.userInfo.roleId || this.jurisdiction) {
        let params={
          ...this.formData
        }
        let message="新增成功"
        if(this.formType==='update'){
          message="修改成功";
        }else{
          delete params.goodstypeId
        }
        addAppointmentGoodsType(params)
          .then(res => {
            if (res.code === 200) {
              this.$message({
                type: "success",
                message: message,
              });
              this.dialogVisible = false;
              this.searchKey = ''
              this.getTableData()
            } else {
              this.$message.error(res.message);
            }
          }).catch(err => {
            if (err.code === 201) {
              this.$message.error(err.message);
            }
          });
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "暂无权限",
      //   });
      //   return
      // }

    },
    //删除
    deleteType(id) {
      // if (this.userInfo.roleId || this.jurisdiction) {
        this.$confirm("此操作将永久删除该分类, 是否继续?", "提示", {
          onConfirmButtonText: "确定",
          cancelButtonText: "取消",
          type: 'warning'
        })
          .then(() => {
            let data = {
              productsTypeId: id,
            };
            delProductsType(data)
              .then(res => {
                this.$message({
                  type: "success",
                  message: res.message,
                });
                this.searchKey = ''
                this.getTableData()
              });

          })
          .catch((err) => {
            //-console.log(err);
            this.$message({
              type: "info",
              message: "取消输入",
            });
          });
      // } else {
      //   this.$message({
      //     type: "warning",
      //     message: "暂无权限",
      //   });
      //   return
      // }

    },
    //当前页变更
    handleCurrentChange(num) {
      this.pageNum = num;
      this.loading = true;
      this.getTableData();
    },
    //当前页查询数据条数变更
    handleSizeChange(num) {
      this.pageSize = num;
      this.pageNum = 1;
      this.currentPage = 1;
      this.loading = true;
      this.getTableData();
    },
  }
};
</script>

<style lang="scss" scoped>
.appointmentList-view {
  background: #ffffff;
  padding: 24px;
}
</style>